<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <PxHeader :activeSearchDesktop="false" :activeSearchMobile="false" />
  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { generateHash } from "@/utils";
import validationToken from "@/mixins/validationToken";
import alertMessageMixin from "@/mixins/alertMessageMixin";
import PxHeader from "@/components/header/PxHeader";

export default {
  inject: ["provider"],
  metaInfo() {
    return {
      title: "Verificar pago",
      meta: [
        { name: "robots", content: "index,follow" },
        { property: "og:locale", content: "es_ES" },
      ],
    };
  },
  mixins: [validationToken, alertMessageMixin],
  components: {
    PxHeader,
  },
  data() {
    const me = this;
    return {
      transationRepository: me.provider.transactionRepository,
      repository: this.provider.publishFarmRepository,
      isBusy: false,
      propertyId: me.$route.query.proId,
      verifyRedirect: me.$route.query.verifyPayment,
      sid: me.$route.query.id,
      record: null,
      verify: null,
    };
  },
  computed: {
    ...mapState("publishFarm", ["step", "dataSave"]),
  },
  methods: {
    ...mapActions("publishFarm", [
      "findStep",
      "addSubcategory",
      "addStep",
      "addDataSave",
    ]),
    async loadFarm() {
      const me = this;
      me.isBusy = true;
      try {
        // edit
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.find(me.propertyId, dataAditional);
        me.record = data;
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      }
    },
    async verifyPayment() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.transationRepository.update({
          ...dataAditional,
          sid: me.sid,
          property_id: me.propertyId,
        });
        me.verify = data;

        if (me.verifyRedirect) {
          if (me.verify.history[0].status == "APPROVED") {
            me.alertMenssage(
              "Se ha actualizado el plan y desde ya esta activo.",
              5000
            );
            me.$router.push("/mis-fincas");
          } else {
            me.alertMenssage(
              "Plan no actualizado, intenta nuevamente el proceso.",
              5000
            );
            me.$router.push({
              name: "stand-out",
              params: { slug: me.record.slug },
            });
          }
        } else {
          me.addDataSave(me.record);
          me.loadStepGroup();
        }
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        if (me.verifyRedirect) me.isBusy = false;
      }
    },
    async loadStepGroup() {
      const me = this;
      me.isBusy = true;
      const selected = me.record.subcategory.map((el) => {
        return el.id;
      });
      me.addSubcategory(selected);
      try {
        const dataAditional = await generateHash();

        const {
          data: { data },
        } = await me.repository.stepGroup({
          ...dataAditional,
          list_subcategories: selected,
        });
        me.addStep(data);
        if (me.verify.history[0].status == "APPROVED") {
          me.findStep(9);
        } else {
          me.findStep(8);
        }
        me.$router.push("/publicar-finca");
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy = false;
      }
    },
  },
  async mounted() {
    const me = this;
    window.scroll(0, 0);
    await me.loadFarm();
    await me.verifyPayment();
  },
};
</script>
